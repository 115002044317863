"use client"

import { useState } from "react"

export default function InterviewAnswerGenerator() {
    const [jobTitle, setJobTitle] = useState("")
    const [jobDescription, setJobDescription] = useState("")
    const [question, setQuestion] = useState("")
    const [answer, setAnswer] = useState("")
    const [copied, setCopied] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showResult, setShowResult] = useState(false)

    const generateAnswer = async () => {
        if (!jobTitle || !question) {
            alert("Please enter a job title and interview question.")
            return
        }

        setLoading(true)
        setCopied(false)

        const prompt = `
You're an experienced job seeker preparing for an interview. 
Here's the role: ${jobTitle}
Job Description: ${jobDescription}
Interview Question: ${question}

Give a realistic, human-like answer as if you're actually in the interview. Use natural tone and structure, not robotic.
Start with a brief introduction like "Thank you for asking. There are a few key reasons why I'm particularly excited about the opportunity to join [Company]."
Then provide 4-5 paragraphs explaining your interest, each focusing on a different aspect (e.g., company reputation, role fit, culture, growth opportunities).
End with a brief conclusion summarizing your enthusiasm.
`

        try {
            const response = await fetch(
                "https://api.openai.com/v1/chat/completions",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer 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`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        model: "gpt-4o",
                        messages: [{ role: "user", content: prompt }],
                        temperature: 0.7,
                        max_tokens: 700,
                    }),
                }
            )

            const data = await response.json()
            const generated = data.choices[0].message.content
            setAnswer(generated)
            setShowResult(true)

            // Scroll to the result section
            setTimeout(() => {
                const resultElement = document.getElementById("answer-result")
                if (resultElement) {
                    resultElement.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    })
                }
            }, 100)
        } catch (error) {
            console.error("Error generating answer:", error)
            alert("Something went wrong. Please try again.")
        } finally {
            setLoading(false)
        }
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(answer)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    const renderFormattedAnswer = () => {
        if (!answer) return null

        // Add a brief intro line
        const introLine =
            "Here's a natural and realistic response to the interview question:"

        // Add a separator
        const separator = "---"

        // Add the interviewer question
        const interviewerQuestion = `Interviewer: ${question}`

        // Add the candidate label
        const candidateLabel = "Candidate:"

        // Split the answer into paragraphs
        const paragraphs = answer.split("\n").filter((p) => p.trim() !== "")

        // Create the final array of elements to render
        const elements = [
            { type: "intro", content: introLine },
            { type: "separator", content: separator },
            { type: "interviewer", content: interviewerQuestion },
            { type: "label", content: candidateLabel },
            ...paragraphs.map((p) => ({ type: "paragraph", content: p })),
        ]

        // Add a final separator
        elements.push({ type: "separator", content: separator })

        // Determine which elements get numbers
        let paragraphCount = 0

        return (
            <div style={{ position: "relative" }}>
                {elements.map((element, index) => {
                    if (element.type === "paragraph") {
                        paragraphCount++

                        return (
                            <div
                                key={index}
                                style={{
                                    position: "relative",
                                    marginBottom: "16px",
                                    marginLeft: "40px",
                                }}
                            >
                                <div style={stepNumberStyle}>
                                    {paragraphCount}
                                </div>
                                <p style={{ margin: 0, lineHeight: 1.6 }}>
                                    {element.content}
                                </p>
                            </div>
                        )
                    } else if (element.type === "separator") {
                        return (
                            <p
                                key={index}
                                style={{ margin: "12px 0", color: "#666" }}
                            >
                                {element.content}
                            </p>
                        )
                    } else if (element.type === "interviewer") {
                        return (
                            <p
                                key={index}
                                style={{ margin: "12px 0", fontWeight: "500" }}
                            >
                                {element.content}
                            </p>
                        )
                    } else if (element.type === "label") {
                        return (
                            <p
                                key={index}
                                style={{ margin: "12px 0", fontWeight: "500" }}
                            >
                                {element.content}
                            </p>
                        )
                    } else {
                        return (
                            <p key={index} style={{ margin: "12px 0" }}>
                                {element.content}
                            </p>
                        )
                    }
                })}
            </div>
        )
    }

    return (
        <div style={{ width: "100%" }}>
            <div style={containerStyle}>
                <div style={formContainerStyle}>
                    <input
                        type="text"
                        placeholder="e.g. Software Engineer"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        style={inputStyle}
                    />
                    <textarea
                        placeholder="Paste job description here..."
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                        style={{ ...inputStyle, height: 100 }}
                    />
                    <textarea
                        placeholder="Why do you want to join us?"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        style={{ ...inputStyle, height: 80 }}
                    />
                    <button
                        onClick={generateAnswer}
                        style={{
                            backgroundColor: "#5E48E8",
                            color: "#fff",
                            padding: "0.8rem 1.5rem",
                            borderRadius: "8px",
                            border: "none",
                            marginTop: "1rem",
                            cursor: loading ? "not-allowed" : "pointer",
                            opacity: loading ? 0.7 : 1,
                            fontWeight: "500",
                        }}
                        disabled={loading}
                    >
                        {loading ? "Generating..." : "Generate Answer"}
                    </button>
                </div>

                {/* Results section that appears below the form */}
                {showResult && (
                    <div id="answer-result" style={resultContainerStyle}>
                        <h2
                            style={{
                                color: "#5E48E8",
                                marginBottom: "1rem",
                                fontSize: "20px",
                                fontWeight: "600",
                            }}
                        >
                            Your Interview Answer
                        </h2>
                        <div style={answerContainerStyle}>
                            {renderFormattedAnswer()}
                        </div>
                        <div style={buttonContainerStyle}>
                            <button
                                onClick={copyToClipboard}
                                style={copyButtonStyle}
                            >
                                {copied ? "Copied!" : "Copy Answer"}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "1.5rem",
    fontFamily:
        "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
    position: "relative",
    zIndex: 1,
}

const formContainerStyle = {
    backgroundColor: "white",
    padding: "1.5rem",
    borderRadius: "12px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    marginBottom: "1.5rem",
}

const resultContainerStyle = {
    backgroundColor: "white",
    padding: "1.5rem",
    borderRadius: "12px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    marginTop: "1.5rem",
    marginBottom: "3rem",
    position: "relative",
    zIndex: 1,
}

const answerContainerStyle = {
    backgroundColor: "#f9fafb",
    padding: "1.5rem",
    borderRadius: "8px",
    marginBottom: "1.5rem",
}

const stepNumberStyle = {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundColor: "#5E48E8",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "14px",
    position: "absolute",
    left: "-40px",
    top: "0",
}

const buttonContainerStyle = {
    display: "flex",
    justifyContent: "flex-end",
}

const inputStyle = {
    width: "100%",
    padding: "0.75rem",
    marginBottom: "1rem",
    borderRadius: "6px",
    border: "1px solid #ddd",
    fontSize: "1rem",
    boxSizing: "border-box",
}

const copyButtonStyle = {
    backgroundColor: "#5E48E8",
    color: "white",
    border: "none",
    padding: "0.6rem 1.2rem",
    borderRadius: "6px",
    cursor: "pointer",
    fontWeight: "500",
    fontSize: "14px",
}
